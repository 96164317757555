import React, {useEffect, useState} from 'react';
import {useCreateOrderMutation, useGetOrderQuery} from "../../redux/rtk/api-rtk";
import InputMask from "react-input-mask";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {removeCartItem, updateCartItem} from "../../redux/slices/cartSlice";


export const Cart = () => {
    const dispatch = useDispatch();
    const [items, setItems] = useState([]);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [deliveryMethod, setDeliveryMethod] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [comment, setComment] = useState('');
    const [createOrder, {error: createOrderError}] = useCreateOrderMutation();
    const navigate = useNavigate();

    // Извлекаем ID товаров из localStorage
    const cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    const productIds = cartItems.map(item => item.id).join(',');

    // Получаем данные о заказе на основе ID товаров
    const {data, error, isLoading} = useGetOrderQuery(productIds);

    useEffect(() => {
        if (data) {
            setItems(data?.products?.map(product => {
                const existingCartItem = cartItems.find(item => item.id === product.id);
                return {
                    ...product,  // Сохраняем все данные продукта
                    quantity: existingCartItem ? existingCartItem.quantity : 1
                };
            }));

            if (!paymentMethod && data?.payment_method.length > 0) {
                setPaymentMethod(data.payment_method[0].id.toString());
            }

            if (!deliveryMethod && data?.shipping_method.length > 0) {
                setDeliveryMethod(data.shipping_method[0].id.toString());
            }
        }
    }, [data, paymentMethod, deliveryMethod]);

// Функция для расчета общей стоимости
    const totalCost = items?.reduce((total, item) => total + item.price * item.quantity, 0);

    // Изменение количества товаров
    const handleQuantityChange = (id, delta) => {
        const updatedItems = items.map(item =>
            item.id === id ? {...item, quantity: Math.max(1, item.quantity + delta)} : item
        );
        setItems(updatedItems);

        // Обновляем Redux через действие
        dispatch(updateCartItem({id, quantity: Math.max(1, updatedItems.find(item => item.id === id).quantity)}));
    };

// Удаление товара из корзины
    const handleRemoveItem = (id) => {
        const updatedItems = items.filter(item => item.id !== id);
        setItems(updatedItems);

        // Обновляем Redux через действие
        dispatch(removeCartItem(id));
    };

    // Отправка заказа
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Проверка наличия токена в localStorage
        const token = localStorage.getItem('token');

        if (!token) {
            // Перенаправляем на страницу входа, если токен отсутствует
            navigate('/login');
            return;
        }

        // Форматируем items, оставляя только id и quantity
        const formattedItems = items.map(item => ({
            id: item.id,
            quantity: item.quantity
        }));

        const formData = new FormData();
        formData.append('payment_method', paymentMethod);
        formData.append('shipping_method', deliveryMethod);
        formData.append('address', address);
        formData.append('phone', phone);
        formData.append('comment', comment);
        formData.append('items', JSON.stringify(formattedItems));

        try {
            const response = await createOrder(formData).unwrap();
            console.log('Заказ успешно отправлен', response);

            localStorage.removeItem('cartItems');
            setItems([]);
        } catch (error) {
            console.error('Ошибка при отправке заказа', error);
        }
    };

    if (isLoading) return <div>Загрузка...</div>;
    if (error) return <div>Ошибка: {error.message}</div>;

    const navigateToProductCart = (key) => {
        navigate(`/catalog/product/${key}`);
    };

    return (
        <div className="cart">
            <div className="cart__container">
                <div className="cart__content">
                    <div className="cart__items">
                        {items ? items.map(item => (
                            <div className="cart__item" key={item.id}>
                                <div className="cart__item-info">
                                    <img src="/images/placeholder.png" alt="product"/>
                                    <div className='cart__description'>
                                        <Link to={`/catalog/product/${item.key}`}>
                                            <h2 className='description__title'>{item.name}</h2>
                                        </Link>
                                        <p className='cart__description-subtext'>Артикул: {item.article}</p>
                                        <p className='cart__description-subtext'>Автозапчасть: {item.type}</p>
                                        <p className='cart__description-subtext'>Производитель: {item.manufacturer}</p>
                                        <div className='cart__footer'>
                                            <p className="cart__item-price">
                                                {item.price * item.quantity} руб.
                                            </p>
                                            <div className="cart__item-quantity">
                                                <button onClick={() => handleQuantityChange(item.id, -1)}
                                                        disabled={item.quantity <= 1}>-
                                                </button>
                                                <span>{item.quantity}</span>
                                                <button onClick={() => handleQuantityChange(item.id, 1)}>+</button>
                                            </div>
                                            <button className='cart__item-remove'
                                                    onClick={() => handleRemoveItem(item.id)}>
                                                Удалить
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )) : <div>Корзина пуста</div>}
                        {items &&
                            <div className='card__cash'>Общая стоимость заказа: <span>{totalCost} руб.</span></div>}
                    </div>
                    <div className="cart__summary">
                        <form className='cart__form' onSubmit={handleSubmit}>
                            <div className="cart__payment-method">
                                <div className='cart__title'>Способ оплаты</div>
                                {data?.payment_method.map((method) => (
                                        <div key={method.id}>
                                            <input
                                                type="radio"
                                                id={`payment-${method.id}`}
                                                name="paymentMethod"
                                                value={method.id.toString()}
                                                checked={paymentMethod === method.id.toString()}
                                                onChange={(e) => setPaymentMethod(e.target.value)}
                                            />
                                            <label htmlFor={`payment-${method.id}`}>{method.name}</label>
                                        </div>
                                    )
                                )}
                                {createOrderError?.data?.payment_method && (
                                    <div className="error-message">
                                        {createOrderError.data.payment_method.map((error, index) => (
                                            <p key={index}>{error}</p>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div className="cart__delivery-method">
                                <div className='cart__title'>Способ доставки</div>
                                {data?.shipping_method.map((method) => (
                                    <div key={method.id}>
                                        <input
                                            type="radio"
                                            id={`shipping-${method.id}`}
                                            name="deliveryMethod"
                                            value={method.id.toString()}
                                            checked={deliveryMethod === method.id.toString()}
                                            onChange={(e) => setDeliveryMethod(e.target.value)}
                                        />
                                        <label htmlFor={`shipping-${method.id}`}>{method.name}</label>
                                    </div>
                                ))}
                                {createOrderError?.data?.shipping_method && (
                                    <div className="error-message">
                                        {createOrderError.data.shipping_method.map((error, index) => (
                                            <p key={index}>{error}</p>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div className='delivery__block'>
                                <div>
                                    <input
                                        className='cart__input'
                                        type="text"
                                        placeholder="Адрес доставки"
                                        value={address}
                                        onChange={(e) => setAddress(e.target.value)}
                                    />
                                    {createOrderError?.data?.errors?.address && (
                                        <div className="error-message">
                                            {createOrderError.data.errors?.address.map((error, index) => (
                                                <p key={index}>{error}</p>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <InputMask
                                        mask="+7 (999) 999-99-99"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                    >
                                        {(inputProps) => <input type="text" {...inputProps} placeholder="Телефон"/>}
                                    </InputMask>
                                    {createOrderError?.data?.errors?.phone && (
                                        <div className="error-message">
                                            {createOrderError.data.errors?.phone.map((error, index) => (
                                                <p key={index}>{error}</p>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <textarea
                                        placeholder="Комментарий к заказу"
                                        value={comment}
                                        onChange={(e) => setComment(e.target.value)}
                                    />
                                </div>
                            </div>
                            <button className='cart__button' type="submit">Оформить заказ</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};
